import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import CookieConsent from "react-cookie-consent"

const NotFoundPageEn = ({ pageContext }) => (
  <Layout language={pageContext.language} wrongPage={true}>
    <div className="page-not-found__wrapper">
      <div className="container">
        <h1>ERROR 404</h1>
        <h2>PAGE NOT FOUND</h2>
        <p>Requested page not found.</p>
        <Link to="/" className="cta-btn cta-btn--hero cta-btn--green">
          Back to homepage
        </Link>
      </div>
    </div>
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="irabigails.com"
      containerClasses={"cookies rounded shadow-lg"}
      buttonClasses={"cookies__btn"}
      expires={150}
    >
      This website uses cookies to ensure you get the best experience on our
      website.
    </CookieConsent>
  </Layout>
)

export default NotFoundPageEn
